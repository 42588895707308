<template>
  <el-pagination
    layout="prev, pager, next"
    :total="paginationsParams.total"
    :page-size="paginationsParams.perPage"
    :current-page.sync="paginationsParams.currentPage"
    :disabled="isRequested"
    @current-change="paginateHandler" />
</template>
<script>

export default {
  name: 'Pagination',
  props: {
    paginationsParams: {
      type: Object,
      default: () => ({}),
    },
    paginateCallback: {
      type: Function,
      default: () => {},
    },
    setPaginate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isRequested: false,
    };
  },
  mounted() {
    const { page } = this.$route.query;
    if (page) {
      this.setPaginate({
        ...this.paginationsParams,
        page: Number(page),
      });
    }
  },
  methods: {
    async paginateHandler(state) {
      if (this.isRequested) {
        return;
      }
      this.isRequested = true;

      await this.paginateCallback(state);
      if (this.$route.query.from) {
        this.$router.replace({ query: { page: state, from: this.$route.query.from } });
      } else {
        this.$router.replace({ query: { page: state } });
      }
      this.isRequested = false;
    },
  },
};
</script>
