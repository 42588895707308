<template>
  <div class="tire-reviews">
    <el-row
      type="flex"
      class="row-bg"
      justify="space-between">
      <el-radio-group
        v-model="from"
        @change="changeReviews">
        <el-radio-button
          label="site">
          Отзывы с сайта
        </el-radio-button>
        <el-radio-button
          label="yandex">
          Отзывы с яндекса
        </el-radio-button>
      </el-radio-group>
      <div>
        <el-button
          :loading="false"
          :type="isEdit ? 'danger' : 'primary'"
          @click="!isEdit ? handlerEdit() : cancelEdit()">
          {{ !isEdit ? 'Редактировать' : 'Отмена' }}
        </el-button>
        <el-button
          :loading="false"
          :disabled="isDisabledSaveBtn"
          :icon="isDisabledSaveBtn ? 'el-icon-close' : 'el-icon-check'"
          :type="isDisabledSaveBtn ? 'primary' : 'success'"
          @click="setTireReviewDescription">
          Сохранить
        </el-button>
      </div>
    </el-row>
    <el-table
      v-loading="isLoading"
      :class="{'linkable-table':!isEdit}"
      :lazy="true"
      :data="tireReviews"
      style="width: 100%">
      <el-table-column
        width="60"
        label="ID"
        prop="id" />
      <el-table-column
        width="80"
        label="Рейтинг"
        prop="rating" />
      <el-table-column
        label="Имя"
        prop="authorFio" />
      <el-table-column
        label="Достоинства"
        prop="contentPlus" />
      <el-table-column
        label="Недостатки"
        prop="contentMinus" />
      <el-table-column
        label="Сорт."
        width="100">
        <template slot-scope="scope">
          <div v-if="!isEdit">
            {{ scope.row.sort }}
          </div>
          <el-input
            v-else
            v-model.number="scope.row.sort"
            type="number"
            size="mini"
            @change="tireReviewEditHandler(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column
        label="Статус"
        width="100">
        <template slot-scope="scope">
          <el-switch
            v-if="isEdit"
            v-model="scope.row.isActive"
            style="display: block"
            active-color="#409EFF"
            inactive-color="#aaa"
            @change="tireReviewEditHandler(scope.row)" />
          <div v-else>
            {{ scope.row.isActive ? 'Виден' : 'Скрыт' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Yandex ID"
        prop="tireReviewYandex.yandexId" />
      <el-table-column
        label="Дата"
        prop="publishedAt" />
      <el-table-column
        fixed="right"
        width="150">
        <template slot-scope="scopeData">
          <router-link
            :to="createLink(scopeData.row.id)">
            <el-button
              type="primary"
              :disabled="isEdit">
              Подробнее
              <i class="el-icon-arrow-right" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <pagination
        layout="prev, pager, next"
        :paginations-params="TIRE_REVIEWS_PAGINATION[from]"
        :paginate-callback="paginateHandler"
        :set-paginate="setTireReviewsPagination" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import has from 'lodash.has';
// eslint-disable-next-line import/no-unresolved
import Pagination from '@/components/ui/pagination';

export default {
  name: 'TireReviewsList',
  components: {
    Pagination,
  },
  data() {
    return {
      isEdit: false,
      search: '',
      editedTireReviews: {},
      from: 'site',
      availableFromVars: ['site', 'yandex'],
      isRequested: false,
    };
  },
  computed: {
    ...mapGetters(['TIRE_REVIEWS', 'TIRE_REVIEWS_PAGINATION', 'TIRE_EDIT_REVIEWS_REQUEST_STATUS', 'TIRE_REVIEWS_REQUEST_STATUS']),
    tireModelId() {
      return this.$route.params.tire_model_id;
    },
    isDisabledSaveBtn() {
      return !Object.keys(this.editedTireReviews).length || this.isRequested;
    },
    tireReviews() {
      return clonedeep(this.TIRE_REVIEWS[this.from]);
    },
    isLoading() {
      return this.TIRE_REVIEWS_REQUEST_STATUS === 'requested';
    },
  },
  watch: {
    TIRE_EDIT_REVIEWS_REQUEST_STATUS(state) {
      if (state === 'success') {
        this.$notify({
          title: 'Готово',
          message: 'Данные успешно изменены',
          type: 'success',
        });
        this.cancelEdit();
      }
    },
  },
  async mounted() {
    if (has(this.$route.query, 'from') && this.availableFromVars.includes(this.$route.query.from)) {
      this.from = this.$route.query.from;
    } else {
      const [defaultQuery] = this.availableFromVars;
      this.from = defaultQuery;
    }

    const page = Number(this.$route.query.page && !Number.isNaN(Number(this.$route.query.page)) ? this.$route.query.page : 1);
    if (Number(this.$route.query.page) !== page || this.$route.query.from !== this.from) {
      this.$router.replace({ query: { page, from: this.from } });
    }
    if (!this.TIRE_REVIEWS[this.from].length) {
      await this.getTireReviewsList({
        id: this.tireModelId,
        params: { ...this.TIRE_REVIEWS_PAGINATION[this.from], page },
        from: this.from,
      });
    }
  },
  methods: {
    ...mapActions(['getTireReviewsList', 'setTireReviewsDescription']),
    ...mapMutations(['setTireReviewsPagination']),
    handlerEdit() {
      this.isEdit = true;
    },
    cancelEdit() {
      this.isEdit = false;
      this.editedTireReviews = {};
    },
    async paginateHandler(curPage) {
      const { perPage } = this.TIRE_REVIEWS_PAGINATION[this.from];
      await this.getTireReviewsList({
        id: this.tireModelId,
        params: {
          perPage,
          page: curPage,
        },
        from: this.from,
      });
    },
    tireReviewEditHandler(review) {
      const reviewFromStore = this.TIRE_REVIEWS[this.from].find(({ id }) => id === review.id);
      if (isEqual(reviewFromStore, review)) {
        this.editedTireReviews = omit(this.editedTireReviews, review.id);
        return;
      }
      const newRewiev = { ...review, sort: Number(review.sort) };
      this.editedTireReviews = {
        ...this.editedTireReviews,
        [review.id]: newRewiev,
      };
    },
    async setTireReviewDescription() {
      this.isRequested = true;
      await this.setTireReviewsDescription(Object.values(this.editedTireReviews));
      await this.getTireReviewsList({
        id: this.tireModelId,
        params: this.TIRE_REVIEWS_PAGINATION[this.from],
        from: this.from,
      });
      this.isRequested = false;
    },
    createLink(id) {
      const tireModelId = this.$route.params.tire_model_id;
      return `/tire-models/${tireModelId}/reviews/${id}`;
    },
    async changeReviews(target) {
      this.$router.replace({ query: { page: 1, from: target } });
      this.from = target;

      if ((!this.TIRE_REVIEWS[this.from].length) || (this.TIRE_REVIEWS_PAGINATION[this.from].currentPage !== 1)) {
        await this.getTireReviewsList({
          id: this.tireModelId,
          params: { ...this.TIRE_REVIEWS_PAGINATION[this.from], page: 1 },
          from: this.from,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.tire-reviews {
  .cell {
    min-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .el-switch {
    span[aria-hidden] {
      color: #aaa;
    }
  }
  .row-bg {
    margin-bottom: 20px;
  }
  .cell {
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-height: 40px;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }
}
</style>
